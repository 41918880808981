import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PostMiniCard from "../components/post-mini-card"

const SeriesTemplate = ({ data, location }) => {
  const series = data.series
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const postsBySeries = [...data.lessons.nodes, ...data.subSeriesLessons.nodes]
  const startDate = postsBySeries.length > 0 ? postsBySeries[0].frontmatter.lessonDate : 'Unknown'
  const endDate = postsBySeries.length > 0 ? postsBySeries[postsBySeries.length - 1].frontmatter.lessonDate : 'Unknown'

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={series.frontmatter.name}
        description={series.frontmatter.description || series.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{series.frontmatter.name}</h1>
          <p>{startDate} - {endDate}</p>
        </header>
        <section className="mt-4">

          <div className="series-description" dangerouslySetInnerHTML={{ __html: series.html }}
            itemProp="articleBody"
          />
        </section>

        <hr />
        <section>
          <h2 className="mt-4">
            Lessons:
            <span className="align-middle inline-flex items-center justify-center px-2 py-1 ml-2 text-xs font-bold leading-none text-gray-200 bg-blue-800 rounded-full">{postsBySeries.length}</span>
          </h2>
          {
            (postsBySeries.map(post => {
              return (
                <PostMiniCard post={post} />
              )
            }))
          }
          
        </section>
      </article>
    </Layout>
  )
}

export default SeriesTemplate

export const pageQuery = graphql`
  query SeriesBySlug(
    $id: String!
    $seriesId: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    series: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
      }
    }
    lessons: allMarkdownRemark(
      sort: { fields: [frontmatter___postDate], order: ASC }
      filter: {
        frontmatter: {
          series: {
            frontmatter: {
              seriesId: {eq: $seriesId}
            }
          },
          docType: {eq: "post"}
        }
      }
    ) {
      totalCount
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          lessonDate(formatString: "MMMM DD, YYYY")
          title
          postId
          postType
          passages
          series {
            frontmatter {
              name
              seriesId
              coverImage {
                childImageSharp {
                  gatsbyImageData(width: 200, layout: FIXED, placeholder: BLURRED)
                }
              }
            }
          }
          subSeries {
            frontmatter {
              name
              seriesId
              coverImage {
                childImageSharp {
                  gatsbyImageData(width: 200, layout: FIXED, placeholder: BLURRED)
                }
              }
            }
          }
          presenters {
            frontmatter {
              name
              presenterId
            }
          }
          venue {
            frontmatter {
              name
            }
          }
        }
      }
    }
    subSeriesLessons: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: ASC }
      filter: {
        frontmatter: {
          subSeries: {
            frontmatter: {
              seriesId: {eq: $seriesId}
            }
          },
          docType: {eq: "post"}
        }
      }
    ) {
      totalCount
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          lessonDate(formatString: "MMMM DD, YYYY")
          title
          postId
          postType
          passages
          series {
            frontmatter {
              name
              seriesId
              coverImage {
                childImageSharp {
                  gatsbyImageData(width: 200, layout: FIXED, placeholder: BLURRED)
                }
              }
            }
          }
          subSeries {
            frontmatter {
              name
              seriesId
              coverImage {
                childImageSharp {
                  gatsbyImageData(width: 200, layout: FIXED, placeholder: BLURRED)
                }
              }
            }
          }
          presenters {
            frontmatter {
              name
              presenterId
            }
          }
          venue {
            frontmatter {
              name
            }
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
